<template>
  <configuration-layout>
    <div class="d-flex align-items-center w-50">
      <b-form-group :label="translations.filters.name" class="flex-fill">
        <b-form-input v-model="filters.name_contains"/>
      </b-form-group>
      <b-form-group :label="translations.filters.sfdc_opportunity_id" class="flex-fill">
        <b-form-input v-model="filters.opportunity_id"/>
      </b-form-group>
      <b-form-group :label="translations.filters.close_date" class="flex-fill">
        <vue-ctk-date-time-picker
          format="YYYY-MM-DD"
          formatted="ll"
          range
          only-date
          :error="!dateRangeValidator(filters.close_date_range)"
          v-model="filters.close_date_range"/>
      </b-form-group>
      <b-button
        data-testid="search-button"
        class="mt-2"
        variant="primary"
        @click="handleSearch">
        <feather type="search"></feather>
      </b-button>
    </div>
    <b-tabs content-class="mt-3">
      <b-tab
        v-for="(tab, index) in tabs"
        :key="tab.key"
        :title="tab.title"
        :active="selectedTab === index"
        @click="selectTab(index)">
        <b-container fluid>
          <component
            v-if="selectedTab === index"
            :is="tab.component"
            :ref="tab.key"
            @mounted="onChildMounted"/>
        </b-container>
      </b-tab>
    </b-tabs>
  </configuration-layout>
</template>

<script>
import translations from '@/translations';
import ConfigurationLayout from '@/components/Commissions/ConfigurationLayout.vue';
import { cloneDeep } from 'lodash';
import DealsList from './dealsList.vue';
import DealsWithPendingConfig from './dealsWithPendingConfig.vue';

export default {
  components: {
    ConfigurationLayout,
    DealsList,
    DealsWithPendingConfig,
  },
  name: 'Deals',
  data() {
    return {
      translations: translations.commissions.deals,
      filters: {
        name_contains: null,
        close_date_range: null,
        opportunity_id: null,
      },
      persistFilters: {},
      selectedTab: 0,
      tabs: [
        {
          key: 'all',
          title: translations.commissions.deals.all_deals.tab_name,
          component: 'DealsList',
        },
        {
          key: 'pending_configs',
          title: translations.commissions.deals.pending_configs.tab_name,
          component: 'DealsWithPendingConfig',
        },
      ],
    };
  },
  beforeMount() {
    // persist selected tab
    const tab = this.$route.query?.tab || 'all';
    const tabIndex = this.tabs.findIndex(t => t.key === tab);
    if (tabIndex !== -1) this.selectedTab = tabIndex;
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
      this.sendFiltersToChildren();
      // reset query params when changing tabs
      const currentRoute = this.$route;
      this.$router.replace({
        path: currentRoute.path,
        query: {
          tab: this.tabs[index].key,
        },
      }).catch(() => {});
    },
    handleSearch() {
      this.persistFilters = cloneDeep(this.filters);

      if (this.persistFilters.close_date_range) {
        const { start, end } = this.persistFilters.close_date_range;
        this.persistFilters.close_date_start = start;
        this.persistFilters.close_date_end = end;
      }
      delete this.persistFilters.close_date_range;

      this.sendFiltersToChildren();
    },
    onChildMounted() {
      this.sendFiltersToChildren();
    },
    sendFiltersToChildren() {
      const children = this.$refs[this.tabs[this.selectedTab].key];
      if (children?.length) children[0].applyFilters(this.persistFilters, 1);
    },
    dateRangeValidator(field) {
      return !field || (field.start && field.end);
    },
  },
};
</script>
